import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

import ptOrange from '../images/pt_orange.gif'
import ptYellow from '../images/pt_yellow.gif'

const AboutPage = () =>
  <Layout>
    <Helmet title="Hexy Music &gt; Contact" />

    <main id="content">
      <h2>&raquo; Contact</h2>

      <h3><img src={ ptYellow } alt="" /> HexyMusic <img src={ ptOrange } alt="" /></h3>
      <p>To send me a message please email: dave DOT transom AT gmail.com</p>
    </main>

    <aside id="ear">
      &raquo;
    </aside>
  </Layout>

export default AboutPage
